.viewpost {
  .our-athlete {

    background-color: rgba(20, 153, 91, 0.25);
    &, a {
      //color: #fff;
    }
  }
  //tr:not(.our-athlete) {
  //  display: none;
  //}

  .postImage {
    display: inline;
    float: right;
    padding: 0 10px 0 10px;
    max-width: 50%;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    .livetimingContent {
      &.resultlist {
        td:nth-last-child(1) {
          display: none;
        }
      }
      &.startlist {
        td:nth-child(2), td:nth-child(6), td:nth-child(7), td:nth-child(9) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .resultlist {
      table {
        table-layout: fixed;
        max-width: 100% !important;
        width: 100% !important;
        tr {
          td {
            min-width: 10px;
          }
          td:nth-child(3), td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8), td:nth-child(9), td:nth-child(11) {
            display: none;
          }
        }
      }
    }
    .startlist {

    }
  }
}
.records {
  .ui.button {
    margin-top: 8px;
  }
  .recordtable {
    width: 100%;
    .recordheader {
      padding: 5px 10px;
      font-family: Oswald, sans-serif;
      font-size: 110%;
    }

    .recordheader.stroke {
      background-color: #05683a;
      color: #fff;
    }

    .recordheader.pool {
      font-weight: bold;
      padding-top: 10px;
    }

    .recordheader.gender {
      font-weight: bold;
      font-size: 120%;
      padding-top: 20px;
    }

    td.date, td.time {
      white-space: nowrap;
    }

    td:not(.recordheader) {
      padding-left: 10px;
      font-size: 90%;
    }

    //&.fina {
    td.name, td.eventname {
      white-space: nowrap;

    }
    //}

    @media screen and (max-width: 414px) {
      td:not(.recordheader) {
        padding-left: 2px;
      }
      &:not(.fina) {
        td.competition, th.competition {
          display: none;
        }
        td.name, th.name {
          div {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    @media screen and (max-width: 320px) {
      &:not(.fina) {
        td.name {
          div {
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.posts .ui.items .item {
  div.content {
    & > a.header {
      font-family: Oswald, sans-serif;
      color: #138446;
      font-weight: 400;
    }
    & > div.meta {
      font-size: 0.8em;
    }
  }
}

.postsWrapper {
  min-height: 300px;

  &.horizontal {
    display: flex;
    flex-wrap: wrap;
    & > .postexcerpt {
      &:not(:last-child) {
        padding-right: 10px;
      }
      width: 25%;
      img, iframe {
        width: 100% !important;
        max-height: 150px;
      }
    }
  }

  .postexcerpt {

    padding-bottom: 20px;

    h1, h2, h3, h4, h5, h6 {
      //font-family: Lato, sans-serif;
      text-transform: uppercase;
      color: #138446;
      margin-bottom: 5px;
      margin-top: 0;
      font-weight: 400;
    }

    .WG1 {
      height: 0;
    }

    a {
      color: inherit;
    }

    .postexcerptBody {
      display: flex;
      justify-content: flex-start;

      .meta {
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.7);
      }
      .postImage {
        //border: 1px solid red;
        width: 100px;
        min-width: 100px;
        margin-right: 10px;
        display: inline-block;
        max-width: 40%;
        float: left;
        img {
          border-radius: 50%;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  color: #138446;
  justify-content: flex-end;

  .pagingNewer, .pagingOlder {
    padding: 0 5px;
  }
  .pagingOlder {

  }
  .pagingPage {
    width: 20px;
    text-align: center;
  }
  .item {
    font-size: 13px;
    font-family: Lato, sans-serif;
    border-radius: 3px;
    //border-right: 1px solid #138446;
    //border-top: 1px solid #138446;
    //border-bottom: 1px solid #138446;
    cursor: pointer;
  }
  //.item:not(:last-child) {
  //  border-right: 1px solid #138446;
  //}
  .item.active {
    background-color: #138446;
    color: #fff;
  }
  //.item:first-child {
  //  border-left: 1px solid #138446;
  //}
}

.posts {
  position: relative;
}

@media screen and (max-width: 376px) {
  .postsWrapper.horizontal {
    .postexcerpt {
      width: 100%;
    }
  }
}
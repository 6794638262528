.startpage {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.newsArea {
  display: inline-block;
  width: 100%;
}

.rightArea {
  display: inline-block;
  width: 100%;
}

.calendar {
  padding-bottom: 20px;
}

.klubbinfo {
}

.posts {
  margin-bottom: 30px;
}

@media screen and (min-width: 769px) {
  .newsArea {
    width: 70%;
    padding-right: 25px;
  }

  .rightArea {
    width: 30%;
  }
  .posts {
    margin-bottom: 10px;
  }

}
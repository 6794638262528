@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,700,700italic|Lato:300,400|Oswald:300,400,500');

$white: rgba(255, 255, 255, .8);
$black: #3c3c3c; //#1b1c1d;
$green: #13814a;
$darkgreen: #05683a;

html {
  font-size: 15px;
}

body {
  margin: 0;
  padding: 0;
  //min-height: 100%;
  background: linear-gradient(rgba(0, 153, 96, 0.45), rgba(0, 153, 96, 0.45)), url('/images/background-large.jpg?v=2') fixed;
  //background: linear-gradient(rgba(0, 153, 96, 0.45), rgba(0, 153, 96, 0.45));
  background-size: auto 100%;
  //display: flex;
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 15px;

  h1, h2, h3, h4, h5 {
    font-weight: 500;
    //line-height: 1.1;
    font-family: Oswald, sans-serif;
    color: #05683a;
  }

  .ui.green.button, .ui.green.buttons .button {
    background-color: $green;
    color: #fff;
    &:hover {
      background-color: lighten($green, 10%);
    }
  }
  &.dimmed.dimmable {
    position: fixed;
  }
}

.root-wrapper {
  align-self: stretch;
  display: flex;
  width: 100%;
  min-height: 100%;
  & > div {
    width: 100%;
    min-height: 100vh;
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }
}

.react-cookie-banner {
  position: fixed !important;
  left: 0;
  height: 56px !important;
  line-height: 56px;
}

.ui.modal > .header:not(.ui) {
  background-color: $green;
  color: rgba(255, 255, 255, 0.9);
}

.ui.modal > .header {
  padding-top: 1em;
  padding-bottom: 1em;
}

.mobileShortcutNav {
  padding-left: 0;
  background-color: $black;
  li {
    list-style-type: none;
    font-size: 1.2em;
    background-color: lighten($black, 20%);
    a {
      color: $white;
    }
    padding: 12px 10px;
    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
}

.buttonArea {
  padding-top: 20px;
  text-align: right;
}

.float-right {
  float: right;
}
.align-right {
  text-align: right !important;
}

.pageHeader {
  background-color: $black;
  .title {
    //font-family: Montserrat, sans-serif;
    position: relative;
    font-family: Oswald, sans-serif;
    font-weight: normal;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    min-height: 60px;

    .icon {
      font-size: 30px;
      line-height: 27px;
      padding-left: 10px;
    }

    img.logo {
      max-height: 50px;
      max-width: 50px;
      cursor: pointer;
    }
    .header {
      font-family: Oswald, sans-serif;
      font-size: 30px;
      line-height: 30px;
      cursor: pointer;
    }
  }

}

.contentArea {
  padding: 20px 10px;
  background-color: #fff;
  flex-grow: 1;
  margin: 5px;
  text-align: justify;
  min-height: 600px;

  .header {
    background-color: inherit;
  }

  .ui.cards {
    margin-top: 0;
  }
}

.ui.dropdown .scrolling.menu {
  position: absolute;

}

.multilevel-dropdown {
  $foreground: $white;
  $background: $green;
  color: $foreground;
  .icon {
    font-size: 30px;
    line-height: 21px;

  }
  & > .icon {
    line-height: 27px;
    margin: 12px 0 12px 0;
  }

  .menu {
    position: absolute;
    background-color: white;
    z-index: 1;
    left: 0;
    width: 100%;
    transition: all 200ms ease-in-out;
    overflow: hidden;
    font-size: 1.25em;
    &.open {
      max-height: 1500px;
      //top: 60px;
    }
    &.closed {
      max-height: 0;
    }
  }
  .menuItemsWrapper {
    position: relative;
  }
  .menuItems {
    background-color: $black;
    font-family: Oswald, sans-serif;
  }
  .subMenu {
    width: 100%;

  }
  .menuItem {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid lighten($black, 5%);

    span {
      padding: 12px 15px;
      cursor: pointer;
      display: block;
      flex-grow: 1;
    }
    &.level1 {
      background-color: lighten($black, 10%);
      border-bottom: 1px solid lighten($black, 15%);
      font-size: 95%;
      padding-left: 10px;
    }
    &.level2 {
      background-color: lighten($black, 20%);
      border-bottom: 1px solid lighten($black, 25%);
      font-size: 90%;
      padding-left: 20px;
    }
    &.level3 {
      background-color: lighten($black, 30%);
      border-bottom: 1px solid lighten($black, 35%);
      font-size: 85%;
      padding-left: 30px;
    }
    &.level4 {
      background-color: lighten($black, 40%);
      border-bottom: 1px solid lighten($black, 45%);
      font-size: 80%;
      padding-left: 40px;
    }

    &.active {
      background-color: $green;
      color: $white;
    }

  }

  .menuArrow {
    font-size: 2em;
    color: #e1e1e1;
    background-color: rgb(5, 104, 58);
    display: flex;
    align-items: center;
    padding: 11px 3px 0 5px;
    cursor: pointer;
    .icon {
      transition: all 200ms ease-in-out;
      transform-origin: 51% 35%;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.notification-bar-active.success-notification {
  background-color: green;
}

@media screen and (min-width: 769px) {

  html {
    font-size: 15px;
  }

  #root {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
    position: relative;
    display: flex;
    background-color: #fff;
  }

  body {
    //max-width: 1280px;
    //width: 100%;
    //margin-left: auto;
    //margin-right: auto;
    //position: relative;
    font-size: 15px;
    width: 100%;
  }

  #root {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    height: 100%;
  }

  .mobileShortcutNav {
    display: none;
  }

  .contentArea {
    padding: 30px 30px;
    //border: 1px solid yellow;
    margin: 0;
    width: 100%;
    //max-width: calc(100% - 250px);
    flex-grow: 1;
    box-sizing: border-box;
  }
  .pageHeader {
    .menuOpenerWrapper {
      display: none;
    }
    .title {
      padding: 0 10px 0 223px;
      min-height: 56px;
      height: 56px;
      img.logo, svg {
        z-index: 2;
        width: 100%;
        max-height: 215px;
        max-width: 215px;
        //height: 200px;
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
        //padding: 20px 45px 0 45px;
        padding: 20px 20px 0 20px;
      }
    }
  }
  .contentWrapper {
    display: inline-flex;
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
  }
  .multilevel-dropdown {
    width: 215px;
    min-width: 215px;
    padding-top: 170px;
    background-color: $black;
    & > div > .icon.bars {
      display: none;
    }
    .menu {
      //position: absolute;
      background-color: $black;
      position: inherit;
      left: 0;
      top: 0;
      min-height: 100%;
      //padding-top: 200px;
      overflow: hidden;
      max-height: 1800px;
      &.closed {
        max-height: inherit;
      }
    }
  }
}
